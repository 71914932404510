<template>
  <div
    v-if="exercise"
    class="mb-12 mt-5"
  >
    <div
      ref="exerciseTop"
      class="exercise-top"
    >
      <div />
    </div>
    <section-in-exam
      :section="section"
      v-if="showSectionInfo"
    />

    <v-banner
      sticky
      app
      :color="$vuetify.theme.currentTheme.background"
      class="exercise-banner"
      v-if="exerciseListMode && !exercise.isImplicit"
    >
      <div>
        <div class="d-flex grey--text font-weight-medium text-body-2">
          <v-expand-x-transition>
            <div
              v-if="!exerciseTopIsVisible && showSectionIndex"
              class="d-inline-block"
              style="overflow: hidden; white-space: nowrap;"
            >
              Section {{ sectionIndex + 1 }}
            </div>
          </v-expand-x-transition>
          <v-divider
            vertical
            class="mx-2"
            v-if="!exerciseTopIsVisible && showSectionIndex"
          />
          <!--          <div class="d-inline-block">-->
          <!--            Page {{ exercise.attributes.position + 1 }}-->
          <!--          </div>-->
          <!--          <v-divider-->
          <!--            vertical-->
          <!--            class="mx-2"-->
          <!--            v-if="position"-->
          <!--          />-->
          <div
            class="d-inline-block grey--text"
            v-if="!exercise.isImplicit"
          >
            {{ subtitle }}
          </div>

          <v-spacer />

          <slot
            name="eventsSummary"
            :show-seen-chip="hasSkippedOrMissingAnswers && quiz.type !== 'paper'"
          />
          <chip-points
            :coefficient="exerciseCoefficientsSum"
            :score="exerciseAnswersScoreSum"
            for-exercise
          />
        </div>

        <div
          v-if="exercise.attributes && !exercise.isImplicit"
        >
          <katex-md
            class="text-h6"
            v-if="exercise.attributes.name"
            :expr="exercise.attributes.name"
            :truncate="!exerciseTopIsVisible"
            :inline-text="!exerciseTopIsVisible"
          />
        </div>
      </div>
    </v-banner>

    <div
      v-if="exercise.attributes && !exercise.isImplicit"
      class="mt-2"
    >
      <template v-if="!exerciseListMode">
        <div
          class="card-subtitle grey--text"
          v-if="!exercise.isImplicit"
        >
          <b>{{ subtitle }}</b>
        </div>

        <katex-md
          class="text-h6"
          v-if="exercise.attributes.name"
          :expr="exercise.attributes.name"
        />
      </template>

      <katex-md
        v-if="exercise.attributes.description"
        :expr="exercise.attributes.description"
      />
    </div>

    <eva-lazy
      v-for="(question, index) in questions"
      :key="`${question.uuid}-${question.id}`"
      :min-height="question.minHeight"
      transition="fade-transition"
      class="mb-12 mt-10"
      :class="{'mt-10': !exercise.isImplicit}"
      :unload-when-not-visible="true"
    >
      <template #default>
        <question-normal
          class="mb-10"
          :question="question"
          :quiz="quiz"
          :index="question.position || exercise.childIndex(index, question)"
          :preview-mode="previewMode"
          :quiz-instance="quizInstance"
          :questions-count="exercise.indexableChildrenCount"
          @questionAnswered="$emit('questionAnswered')"
          :correction-mode="correctionMode"
          :show-score="showScore && (quiz.show_scores || currentUser.teacher)"
          :user-id="userId"
          :allow-navigation="quiz.allow_questions_navigation"
          :manual-correction-mode="manualCorrectionMode"
          :exercise="exercise"
          :quiz-score="quizScore"
          :quizzes-attempt-summary="quizzesAttemptSummary"
        >
          <template #eventsSummary>
            <slot
              name="eventsSummary"
              v-if="exercise.isImplicit"
              :show-seen-chip="hasSkippedOrMissingAnswers && quiz.type !== 'paper'"
            />
          </template>
          <template #grader="{answer, question}">
            <slot
              name="grader"
              :answer="answer"
              :question="question"
            />
          </template>
          <template #feedbackEditor="{answer, question, questionComment, hasQuestionComment }">
            <slot
              name="feedbackEditor"
              :answer="answer"
              :question="question"
              :question-comment="questionComment"
              :has-question-comment="hasQuestionComment"
            />
          </template>
        </question-normal>
      </template>
    </eva-lazy>
  </div>
</template>

<script>
import QuestionNormal from "../../../questions/question_normal.vue"
import KatexMd from "../../../katex-md.vue"
import { mapGetters } from "vuex"
import SectionInExam from "./sectionInExam.vue"
import { ref } from "vue"
import { useElementVisibility } from "@vueuse/core"
import ChipPoints from "@/components/questions/chip_points.vue"
import EvaLazy from "@/components/shared/evaLazy.vue"
export default {
  name: "ExerciseStudent",
  components: { ChipPoints, SectionInExam, KatexMd, QuestionNormal, EvaLazy },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
    quizInstance: {
      type: Object,
      default: null,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    correctionMode: {
      type: Boolean,
      default: false,
    },
    manualCorrectionMode: {
      type: Boolean,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
    questionsFilter: {
      type: Function,
      default: () => true,
    },
    showSectionInfo: {
      type: Boolean,
      default: false,
    },
    showScore: {
      type: Boolean,
      default: true,
    },
    quizScore: {
      type: Object,
      default: null,
    },
    quizzesAttemptSummary: {
      type: Object,
      default: null,
    },
    sectionIndex: {
      type: Number,
      default: null,
    },
    showSectionIndex: {
      type: Boolean,
      default: false,
    },
    exerciseListMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const exerciseTop = ref(null)
    const exerciseTopIsVisible = useElementVisibility(exerciseTop)

    return {
      exerciseTop,
      exerciseTopIsVisible,
    }
  },
  computed: {
    ...mapGetters(["currentUser", "answersByQuestionsAndUserId", "questionById"]),
    questions() {
      if( !this.exercise || !this.exercise.children ) return []
      return this.exercise.children.filter(this.questionsFilter).filter(question => question.type)
    },
    // Dorian 2024-12-16
    // Answer should not be retrieved here as it does not make sense.
    // This is only used to compute the score of the exercise
    answers() {
      return this.answersByQuestionsAndUserId(this.questions.map(q => q.id), this.userId)
    },
    hasSkippedOrMissingAnswers() {
      if(this.answers.length < this.questions.length) return true
      return this.answers.some(a => a.skipped)
    },
    section() {
      return this.exercise?.section
    },
    position() {
      return this.exercise.attributes.position
    },
    subtitle() {
      if (this.position == null) return
      return `Exercice ${this.position + 1}`
    },
    exerciseCoefficientsSum() {
      return this.exercise.children.reduce((acc, q) => acc + this.questionWithCorrectionById(q.id)?.coefficient || 0, 0)
    },
    exerciseAnswersScoreSum() {
      return this.answers.reduce((acc, q) => acc + q.score, 0)
    },
  },
  methods: {
    questionWithCorrectionById(id) {
      return this.questionById(id)
    },
  },
}
</script>

<style>
.exercise-banner .v-banner__wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

.exercise-top {
  position: relative;
  overflow: visible;
  height: 0;
}
</style>
